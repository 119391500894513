import React from "react";
import {
  Text,
  Button,  
  HStack,
  Center,
  Heading,
  Switch,
  useColorMode,
  NativeBaseProvider,  
  VStack,
  Spacer,  
  //StatusBar,
  //Box,
  Icon,
  Spinner
} from "native-base";

import { MaterialCommunityIcons } from '@expo/vector-icons'
import * as WebBrowser from 'expo-web-browser';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";

import Choices from './src/Choices';
import Timezones from "./src/Timezones";
import Categories from "./src/Categories";
import TimezoneWebinars from "./src/tzwebinars";
import CategoryWebinars from "./src/catwebinars";


// Define the config
//const config = {
//  useSystemColorMode: false,
//  initialColorMode: "dark",
//};

// extend the theme
//export const theme = extendTheme({ config });

class StartScreen extends React.Component {
  state = {
    loading: true,
    token: 'e6bb70326ffc7294ad0e1a08ad2d7bb0ba239ae2',
    domain: 'https://vorweb.pythonanywhere.com/api/several/options/v1/',
    imageurlhead: 'https://vorweb.pythonanywhere.com',
    tokenlocal: '4902e8b11a3fdfcd6bcf96726efb188e03a2689c',   
    domainlocal:  'http://192.168.43.120:8000/api/several/options/v1/',       
    imageurlheadlocal: 'http://192.168.43.120:8000',
    
    //domain_country: '.pythonanywhere.com/api/several/v1/countries',    
    //token_country: ' '

  }
  
  async componentDidMount() {
    this.setState({loading: false,});
  } // componentDidMount

  render() {    
    if (this.state.loading) {
      return (
        <NativeBaseProvider>
        
        <Center>
          <Spinner />
          <Spinner color='red' />
          <Spinner color='green' />
          <Spinner color='blue' />
        </Center>
      </NativeBaseProvider>
      );
    }
  return (
    <NativeBaseProvider>

        
        <HStack bg="#0891b2" px="5" py="3" justifyContent="space-between" alignItems="center" w="100%">
          <HStack alignItems="center">            
          <Icon as={<MaterialCommunityIcons name="home-outline" />} color="white" />      
            <Text color="white" fontSize="20" fontWeight="bold"> Home</Text>            
          </HStack>                             
        </HStack>

      <Center
        //_dark={{ bg: "blueGray.900" }}
        //_light={{ bg: "white" }}
        px={4}
        flex={1}>
        <VStack space={5} alignItems="center">


        
          <Heading size="lg">Welcome to Vorrinize</Heading>
          <HStack space={1} alignItems="center">
            <Text fontSize="22">Browse and Find a Webinar</Text>            
          </HStack>     
          <Spacer />     
          <Button _text={{color: "white", fontWeight: "600",
            fontSize: "xl"}} size="lg" onPress={() => this.props.navigation.navigate('Choices',
          {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead,})}>Continue</Button>
          <Spacer />
          <Spacer />
          
        </VStack>
      </Center>
      
        <HStack bg="#0891b2" alignItems="center" safeAreaBottom shadow={6}>
        <Spacer />     
        <Button variant="unstyled" onPress={() => WebBrowser.openBrowserAsync('https://www.vorrinize.com/')}>
            <Text color="white">&copy; Vorrinize</Text>
          </Button>
          <Spacer />     
        </HStack>
      
    </NativeBaseProvider>
  );
}
}


// Color Switch Component
function ToggleDarkMode() {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <HStack space={2} alignItems="center">
      <Text>Dark</Text>
      <Switch
        isChecked={colorMode === "light"}
        onToggle={toggleColorMode}
        aria-label={
          colorMode === "light" ? "switch to dark mode" : "switch to light mode"
        }
      />
      <Text>Light</Text>
    </HStack>
  );
}


const Stack = createNativeStackNavigator();

function OurStackNavigator() {
  return (
    <NavigationContainer>
      <Stack.Navigator>
        <Stack.Screen name="Home" component={StartScreen}
                                  options={{ 
                                    title: '',
                                    headerLeft: () => null,
                                    headerStyle: {
                                      height: 0
                                    },     
                                   }} />
        <Stack.Screen name="Choices" component={Choices}
                                  options={{ 
                                    title: '',
                                    headerLeft: () => null,
                                    headerStyle: {
                                      height: 0
                                    },     
                                   }} />                                   
         <Stack.Screen name="Timezones" component={Timezones}
                                  options={{ 
                                    title: '',
                                    headerLeft: () => null,
                                    headerStyle: {
                                      height: 0
                                    },     
                                   }} />      
          <Stack.Screen name="Categories" component={Categories}
                                  options={{ 
                                    title: '',
                                    headerLeft: () => null,
                                    headerStyle: {
                                      height: 0
                                    },     
                                   }} />                                               
          <Stack.Screen name="TimezoneWebinars" component={TimezoneWebinars}
                                  options={{ 
                                    title: '',
                                    headerLeft: () => null,
                                    headerStyle: {
                                      height: 0
                                    },     
                                   }} />   
           <Stack.Screen name="CategoryWebinars" component={CategoryWebinars}
                                  options={{ 
                                    title: '',
                                    headerLeft: () => null,
                                    headerStyle: {
                                      height: 0
                                    },     
                                   }} />       
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default OurStackNavigator;

serviceWorkerRegistration.register();