import React from "react";
import { Dimensions, FlatList, Alert, ScrollView} from "react-native";
import {
  Text,  
  Button,  
  HStack,
  //Icon,
  NativeBaseProvider,
  Pressable,
  VStack,
  Center,
  Spacer,  
  StatusBar,
  Image,
  Box,
  Spinner,
  //AspectRatio,
  //Divider,
} from "native-base";
//import { MaterialCommunityIcons } from '@expo/vector-icons'
import * as WebBrowser from 'expo-web-browser';
import nowebinar from "../assets/nowebinar.png"


export default class CategoryWebinars extends React.Component {
    state = {
      loading: true,      
      webinars: [],
      render: false
    }
    

    async componentDidMount() {
      this._mounted = true;
    //var token=this.props.navigation.getParam('token');
    //var domain=this.props.navigation.getParam('domain');        
    //var imageurlhead=this.props.navigation.getParam('imageurlhead'); 
  
    const { id, cat_name, token, domain, imageurlhead } = this.props.route.params;
    this.setState({ loading: false, cat_name: cat_name, token: token, domain: domain, imageurlhead: imageurlhead,});
    //this.setState({ loading: false});
    
    await fetch(domain+'webinars/category/'+id, {
      method: 'GET',
      headers: {'Authorization': 'Token '+token}         
    })
    .then((res) =>
    {if (res.status === 401)
       {
         Alert.alert ("Error", "Invalid Token.\nPlease sign in again.",            
         [            
          {text: 'OK', onPress: () => this.props.navigation.navigate('Home')},
        ],          
        )
         
    }
    else{
      if (this._mounted) {
    res.json() 
      .then((data) => {
        this.setState({loading: false, webinars: data, render: true,
        }) // setState
      })   // then(data)   
    } // if this mounted
    this._mounted = false;
    } // else
    }) // if and res
    .catch(error => {        
      Alert.alert ("Alert", "The server may be down.  Please try again later.",            
        [            
         {text: 'OK', onPress: () => this.props.navigation.navigate('Home')},
       ],          
       )
    }) // catch error

  } // componentDidMount

  componentWillUnmount(){
    this._mounted = false;
  }
  
    render(){
        //const SCREEN_WIDTH = Dimensions.get('window').width;
        let SCREEN_WIDTH = (Dimensions.get('window').width > 480) ? 480 : Dimensions.get('window').width; 

        if (this.state.loading) {
          return (
            <NativeBaseProvider>
        
                <StatusBar bg="#0891b2" barStyle="light-content" />
                <Box safeAreaTop bg="#0891b2" />
                <HStack bg="#0891b2" px="5" py="3" justifyContent="space-between" alignItems="center" w="100%">
                  <HStack alignItems="center">            
                    <Text color="white" fontSize="20" fontWeight="bold">
                      {this.state.cat_name}
                    </Text>            
                  </HStack>                   
                </HStack>   
                  
                <ScrollView>
                  <Center>
                <VStack space={5} px="1" py="1" flex="1">
                <Spacer /><Spacer /><Spacer />
                <VStack space={8} justifyContent="center">
            <Spinner color="emerald.500" />
              <Spinner color="warning.500" />
              <Spinner color="indigo.500" />
              <Spinner color="cyan.500" />
              </VStack> 
               
                </VStack> </Center>
                </ScrollView>
      
                <HStack bg="#0891b2" alignItems="center" safeAreaBottom shadow={6}>
                <Spacer />
                <Button variant="unstyled" onPress={() => this.props.navigation.navigate('Categories',
          {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead,})}>            
            <Text fontWeight="bold" color="white" >Categories</Text>
            </Button>
                  <Spacer />
              <Button variant="unstyled" onPress={() => this.props.navigation.navigate('Choices',
                {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead,})}>            
                  <Text fontWeight="bold" color="white" >Choices</Text>
                  </Button>
                  <Spacer />
                  
                  
              </HStack>
            
            </NativeBaseProvider>
          );
        }

        else if (this.state.webinars.length === 0){
          return (
            <NativeBaseProvider>
        
                <StatusBar bg="#0891b2" barStyle="light-content" />
                <Box safeAreaTop bg="#0891b2" />
                <HStack bg="#0891b2" px="5" py="3" justifyContent="space-between" alignItems="center" w="100%">
                  <HStack alignItems="center">            
                    <Text color="white" fontSize="20" fontWeight="bold">
                      {this.state.cat_name}
                    </Text>            
                  </HStack>                   
                </HStack>   
                  
                <ScrollView>
                  <Center>
                <VStack space={5} px="1" py="1" flex="1">

                {this.state.render ?
                <Box rounded="lg"  borderColor="coolGray.200" borderWidth="1" 
              _web={{shadow: 2, borderWidth: 0}} 
              _light={{backgroundColor: "white"}}>
              <Image rounded="lg" style={{ width: SCREEN_WIDTH-4, height: 400/800*(SCREEN_WIDTH-4)}} 
              source={nowebinar} alt="nowebinar" />
            </Box> : null}    
               
                </VStack> </Center>
                </ScrollView>
      
                <HStack bg="#0891b2" alignItems="center" safeAreaBottom shadow={6}>
                <Spacer />
                <Button variant="unstyled" onPress={() => this.props.navigation.navigate('Categories',
          {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead,})}>            
            <Text fontWeight="bold" color="white" >Categories</Text>
            </Button>
                  <Spacer />
              <Button variant="unstyled" onPress={() => this.props.navigation.navigate('Choices',
                {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead,})}>            
                  <Text fontWeight="bold" color="white" >Choices</Text>
                  </Button>
                  <Spacer />
                  
                  
              </HStack>
            
            </NativeBaseProvider>
          );
        }

    return (
      <NativeBaseProvider>
  
          <StatusBar bg="#0891b2" barStyle="light-content" />
          <Box safeAreaTop bg="#0891b2" />
          <HStack bg="#0891b2" px="5" py="3" justifyContent="space-between" alignItems="center" w="100%">
            <HStack alignItems="center">            
              <Text color="white" fontSize="20" fontWeight="bold">
                {this.state.cat_name}
              </Text>            
            </HStack>                   
          </HStack>   
            
          <ScrollView>
            <Center>
          <VStack space={5} px="1" py="1" flex="1">

          {this.state.webinars.map((webinar) => (
              <Pressable key={webinar.id} onPress={() => WebBrowser.openBrowserAsync(webinar.website)}>
              <Box rounded="lg"  borderColor="coolGray.200" borderWidth="1" 
              _web={{shadow: 2, borderWidth: 0}} 
              _light={{backgroundColor: "white"}}>               

            <Image rounded="lg" style={{ width: SCREEN_WIDTH-4, height: webinar.dimension[1]/webinar.dimension[0]*(SCREEN_WIDTH-4) }} 
              source={{uri: this.state.imageurlhead+webinar.image}} alt="image" />
            
            </Box></Pressable>
            ))}
          </VStack> </Center>
          </ScrollView> 

          <HStack bg="#0891b2" alignItems="center" safeAreaBottom shadow={6}>
          <Spacer />
          <Button variant="unstyled" onPress={() => this.props.navigation.navigate('Categories',
          {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead,})}>            
            <Text fontWeight="bold" color="white" >Categories</Text>
            </Button>
            <Spacer />
        <Button variant="unstyled" onPress={() => this.props.navigation.navigate('Choices',
          {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead,})}>            
            <Text fontWeight="bold" color="white" >Choices</Text>
            </Button>
            <Spacer />
            
            
        </HStack>
      
      </NativeBaseProvider>
    );
  }  // RENDER
  }