import React from "react";
import { Dimensions, FlatList, Alert, ScrollView} from "react-native";
import {
  Text,
  Button,  
  HStack,
  Icon,
  NativeBaseProvider,
  Pressable,
  VStack,
  //Stack,
  Spacer,  
  //StatusBar,
  Image,
  Box,
  //Heading,
  Center,
  Spinner,
} from "native-base";
import { MaterialCommunityIcons } from '@expo/vector-icons'


import topic from '../assets/topic.png'
import tz from '../assets/tz.png'

export default class Choices extends React.Component {
    state = {
      loading: true,      
    }
    
    async componentDidMount() {
      
    //var token=this.props.navigation.getParam('token');
    //var domain=this.props.navigation.getParam('domain');        
    //var imageurlhead=this.props.navigation.getParam('imageurlhead');    
    const { token, domain, imageurlhead } = this.props.route.params;
    this.setState({ loading: false, token: token, domain: domain, imageurlhead: imageurlhead});
    //this.setState({ loading: false});
    
  } // componentDidMount
  
  
    render(){
      let SCREEN_WIDTH = (Dimensions.get('window').width > 480) ? 480 : Dimensions.get('window').width; 
      
      if (this.state.loading) {
        return (
          <NativeBaseProvider>
          
          <HStack space={8} justifyContent="center">
          <Spinner color="emerald.500" />
            <Spinner color="warning.500" />
            <Spinner color="indigo.500" />
            <Spinner color="cyan.500" />
            </HStack>   
          
        </NativeBaseProvider>
        );
      }

    return (
      <NativeBaseProvider >
         
         
          <HStack bg="#0891b2" px="5" py="3" justifyContent="space-between" alignItems="center" w="100%">
            <HStack alignItems="center">              
              <Text color="white" fontSize="20" fontWeight="bold"> Choices</Text>            
            </HStack>                   
          </HStack>       

          <ScrollView >
          <Center>
          <VStack space={5} px="2" py="2" flex="1" >
        
         

          <Pressable onPress={() => this.props.navigation.navigate('Categories',
          {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead,})}>
          <Box rounded="lg" overflow="hidden" borderColor="coolGray.200" borderWidth="2" 
            _web={{shadow: 2, borderWidth: 0, backgroundColor: "yellow"}} 
            _light={{backgroundColor: "white"}}>
             
             <Image rounded="lg" style={{ width: SCREEN_WIDTH-4, height: (SCREEN_WIDTH-4)/2 }} 
              source={topic} alt="image" />
            <Center bg="#0891b2" _text={{color: "white", fontWeight: "600",
            fontSize: "md"}} position="absolute" bottom="0" px="3" py="1.5">
              BROWSE BY CATEGORY
            </Center>

          </Box>
          </Pressable>
        
          <Pressable onPress={() => this.props.navigation.navigate('Timezones',
          {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead,})}>
          <Box rounded="lg" overflow="hidden" borderColor="coolGray.200" borderWidth="2" 
            _web={{shadow: 2, borderWidth: 0, backgroundColor: "yellow"}} 
            _light={{backgroundColor: "white"}}>
             
             <Image rounded="lg" style={{ width: SCREEN_WIDTH-4, height: (SCREEN_WIDTH-4)/2 }} 
              source={tz} alt="image" />
            <Center bg="#0891b2" _text={{color: "white", fontWeight: "600",
            fontSize: "md"}} position="absolute" bottom="0" px="3" py="1.5">
              BROWSE BY TIMEZONE
            </Center>

          </Box>
          </Pressable>

          </VStack> </Center>
          </ScrollView>

          <HStack bg="#0891b2" alignItems="center" safeAreaBottom shadow={6}>
        <Spacer />     
        <Button variant="unstyled" onPress={() => this.props.navigation.navigate('Home',
          {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead,})}>
             <Icon as={<MaterialCommunityIcons name="home-outline" />} color="white" />            
          </Button>
          <Spacer />     
        </HStack>
        
      </NativeBaseProvider>
    );
  }
  }