import React from "react";
import { Dimensions, Alert, ScrollView} from "react-native";
import {
  Text,
  Button,  
  HStack,
  //Icon,
  NativeBaseProvider,
  Pressable,
  VStack,
  Spacer,  
  StatusBar,
  Image,
  Box,
  Center,
  Spinner
} from "native-base";
//import { MaterialCommunityIcons } from '@expo/vector-icons'



export default class Categories extends React.Component {
    state = {
      loading: true,      
      categories: []
    }
    

    async componentDidMount() {
      this._mounted = true;
    //var token=this.props.navigation.getParam('token');
    //var domain=this.props.navigation.getParam('domain');        
    //var imageurlhead=this.props.navigation.getParam('imageurlhead'); 
  
    const { token, domain, imageurlhead } = this.props.route.params;
    this.setState({ loading: false, token: token, domain: domain, imageurlhead: imageurlhead,});
    //this.setState({ loading: false});
    
    await fetch(domain+'categories', {
      method: 'GET',
      headers: {'Authorization': 'Token '+token}         
    })
    .then((res) =>
    {if (res.status === 401)
       {
         Alert.alert ("Error", "Invalid Token.\nPlease sign in again.",            
         [            
          {text: 'OK', onPress: () => this.props.navigation.navigate('Home')},
        ],          
        )
         
    }
    else{
      if (this._mounted) {
    res.json() 
      .then((data) => {
        this.setState({loading: false, categories: data
        }) // setState
      })   // then(data)   
    } // if this mounted
    this._mounted = false;
    } // else
    }) // if and res
    .catch(error => {        
      Alert.alert ("Alert", "The server may be down.  Please try again later.",            
        [            
         {text: 'OK', onPress: () => this.props.navigation.navigate('Home')},
       ],          
       )
    }) // catch error

  } // componentDidMount

  componentWillUnmount(){
    this._mounted = false;
  }
  
    render(){
      let SCREEN_WIDTH = (Dimensions.get('window').width > 480) ? 480 : Dimensions.get('window').width; 
      if (this.state.loading) {
        return (
          <NativeBaseProvider >
  
          <StatusBar bg="#0891b2" barStyle="light-content" />
          <Box safeAreaTop bg="#0891b2" />
          <HStack bg="#0891b2" px="5" py="3" justifyContent="space-between" alignItems="center" w="100%">
            <HStack alignItems="center">            
              <Text color="white" fontSize="20" fontWeight="bold">
                Categories
              </Text>            
            </HStack>                   
          </HStack>   

          <ScrollView>
                  <Center>
                <VStack space={5} px="1" py="1" flex="1">
                <Spacer /><Spacer /><Spacer />
                <VStack space={8} justifyContent="center">
            <Spinner color="emerald.500" />
              <Spinner color="warning.500" />
              <Spinner color="indigo.500" />
              <Spinner color="cyan.500" />
              </VStack> 
               
                </VStack> </Center>
                </ScrollView>  

          <HStack bg="#0891b2" alignItems="center" safeAreaBottom shadow={6}>
          <Spacer />
          <Button variant="unstyled" onPress={() => this.props.navigation.navigate('Home',
          {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead,})}>            
            <Text fontWeight="bold" color="white" >Home</Text>
            </Button> 
            <Spacer />  
        <Button variant="unstyled" onPress={() => this.props.navigation.navigate('Choices',
          {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead,})}>            
            <Text fontWeight="bold" color="white" >Choices</Text>
            </Button>
            <Spacer />
           
        </HStack>
      
      </NativeBaseProvider>
        );
      }

    return (
      <NativeBaseProvider >
  
          <StatusBar bg="#0891b2" barStyle="light-content" />
          <Box safeAreaTop bg="#0891b2" />
          <HStack bg="#0891b2" px="5" py="3" justifyContent="space-between" alignItems="center" w="100%">
            <HStack alignItems="center">            
              <Text color="white" fontSize="20" fontWeight="bold">
                Categories
              </Text>            
            </HStack>                   
          </HStack>   

          <ScrollView>
            <Center>
          <VStack space={5} px="2" py="2" flex="1" width={SCREEN_WIDTH}>

          {this.state.categories.map((cat) => (
            <Pressable key={cat.id} onPress={() => this.props.navigation.navigate('CategoryWebinars',
            {id: cat.id, cat_name: cat.name, token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead,})}>
              <Box rounded="lg"  borderColor="coolGray.200" borderWidth="2"               
              _web={{shadow: 2, borderWidth: 0}} 
              _light={{backgroundColor: "white"}}>
               
              <HStack p="4" space={2} >             
              <Image style={{ width: 25, height: 25 }} source={{uri: this.state.imageurlhead+cat.image}} alt="category image" />
                  <Text fontWeight="600" fontSize="md"> {cat.name}</Text>    
              </HStack>
            </Box></Pressable>
      ))}
         
         
          </VStack> </Center>
          </ScrollView>        

          <HStack bg="#0891b2" alignItems="center" safeAreaBottom shadow={6}>
          <Spacer />
          <Button variant="unstyled" onPress={() => this.props.navigation.navigate('Home',
          {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead,})}>            
            <Text fontWeight="bold" color="white" >Home</Text>
            </Button> 
            <Spacer />  
        <Button variant="unstyled" onPress={() => this.props.navigation.navigate('Choices',
          {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead,})}>            
            <Text fontWeight="bold" color="white" >Choices</Text>
            </Button>
            <Spacer />
           
        </HStack>
      
      </NativeBaseProvider>
    );
  }
  }